import { render, staticRenderFns } from "./DreamTreeRelations.vue?vue&type=template&id=3c54c468&scoped=true"
import script from "./DreamTreeRelations.vue?vue&type=script&lang=ts"
export * from "./DreamTreeRelations.vue?vue&type=script&lang=ts"
import style0 from "./DreamTreeRelations.vue?vue&type=style&index=0&id=3c54c468&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c54c468",
  null
  
)

export default component.exports