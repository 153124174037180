
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import { dreamsActions } from '@/store/modules/dreams/names';
import { ComposedState, IRelationItem, IRelations } from '@/types';

import rest from '@/api/index';

export default Vue.extend({
    name: 'DreamTreeRealtions',

    props: {
        dreamId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            activeTab: 0,
            levels: [
                {
                    id: 1,
                    title: '5',
                },
                {
                    id: 2,
                    title: '15',
                },
                {
                    id: 3,
                    title: '30',
                },
            ],
            isShowChangeRelationsModal: false,
            positionId: 0,
            isCanEditLink: false,
            isShowError: false,
            errorMessage: '',
        };
    },

    computed: {
        denomination() {
            switch (this.activeTab) {
                case 0:
                    return 5;
                case 1:
                    return 15;
                case 2:
                    return 30;
                default:
                    return 5;
            }
        },
        ...mapState<ComposedState>({
            relations: (state: ComposedState) => state.dreams.relations,
        }),
    },

    watch: {
        async denomination(newval) {
            if (newval) {
                await this.getRelations({ dreamId: this.dreamId, rate: this.denomination });
            }   
        },

        async '$route.params.id'(newval) {
            if (newval) {
                this.$router.go(this.$router.currentRoute as any);
            }
        },
    },

    async mounted() {
        await this.getRelations({ dreamId: this.dreamId, rate: this.denomination });
    },

    beforeDestroy() {
        this.positionId = 0;
        this.isCanEditLink = false;
        this.isShowError = false;
        this.errorMessage = '';
    },

    methods: {
        ...mapActions({
            getRelations: dreamsActions.getRelations,
        }),

        openModalToChangeRelation(dream: IRelationItem | IRelations, isParent?: boolean) {
            this.positionId = isParent
                ? dream.position_id : (dream as IRelationItem).position_id;
            this.isCanEditLink = isParent
                ? (dream as IRelations).parent.can_edit_link
                : (dream as IRelationItem).can_edit_link;
            this.isShowChangeRelationsModal = true;
        },

        async onChangeRelation(isDelete?: boolean) {
            try {
                const res = isDelete === true
                    ? await rest.deleteRelation(this.positionId)
                    : await rest.changeRelation(this.positionId);
            
                if (res.status === 200) {
                    await this.getRelations({ dreamId: this.dreamId, rate: this.denomination });
                    this.isShowChangeRelationsModal = false;
                }

            } catch (e) {
                const error = Object.values(e as Object)[2];
                
                const message = error.status === 403
                    ? 'This link cannot be changed' : 'Something was wrong';
                
                this.errorMessage = message;
                
                this.isShowError = true;
            }
        },
    },
});
