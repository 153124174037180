var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"wrapper"},[_c('v-tabs',{attrs:{"right":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.levels),function(level){return _c('v-tab',{key:level.id},[_vm._v(_vm._s(level.title))])}),_vm._l((_vm.levels),function(level){return _c('v-tab-item',{key:level.id},_vm._l((_vm.relations),function(tree){return _c('div',{key:`${tree.position_id}-${tree.dream_id}`,staticClass:"tree"},[_c('div',{staticClass:"tree__node tree__node__upper-dream"},[_c('div',{staticClass:"tree__node-block"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/dreams/' + tree.parent.dream_id }}},[_vm._v(" "+_vm._s(tree.parent.dream_title)+" ")])],1),_c('div',{class:[
                            'tree__node-relation',
                            tree.parent.link_type === 'PERMANENT'
                                && 'tree__node-permanent'
                        ],on:{"click":function($event){return _vm.openModalToChangeRelation(tree, true)}}},[_c('span')])]),_c('div',{staticClass:"tree__node"},[_c('div',{staticClass:"tree__node-block"},[_vm._v(" "+_vm._s(tree.dream_title)+" ")])]),_c('div',{staticClass:"tree__node tree__node__bottom-dream"},_vm._l((tree.childs),function(dream,index){return _c('div',{key:`${dream.position_id}-${dream.dream_id}`,staticClass:"tree__node"},[_c('div',{class:[
                                'tree__node-relation',
                                `tree__node-relation-${index}`,
                                dream.link_type === 'PERMANENT'
                                    && 'tree__node-permanent'
                            ],on:{"click":function($event){return _vm.openModalToChangeRelation(dream)}}},[_c('span')]),_c('div',{staticClass:"tree__node-block"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/dreams/' + dream.dream_id }}},[_vm._v(" "+_vm._s(dream.dream_title)+" ")])],1)])}),0)])}),0)})],2),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.isShowChangeRelationsModal),callback:function ($$v) {_vm.isShowChangeRelationsModal=$$v},expression:"isShowChangeRelationsModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" How do you want to change the relation? ")]),_c('v-card-actions',[_c('v-spacer'),(_vm.isCanEditLink)?_c('div',[_c('v-btn',{attrs:{"color":"green","variant":"text"},on:{"click":_vm.onChangeRelation}},[_vm._v(" Change ")]),_c('v-btn',{attrs:{"color":"red","variant":"text"},on:{"click":function($event){return _vm.onChangeRelation(true)}}},[_vm._v(" Delete ")])],1):_c('p',[_vm._v(" There is nothing you can do about this relation. ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000},model:{value:(_vm.isShowError),callback:function ($$v) {_vm.isShowError=$$v},expression:"isShowError"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }