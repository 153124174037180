

// @ts-nocheck

import Vue from 'vue';

import { mapState, mapActions, mapMutations } from 'vuex';

import { dreamsActions, dreamsMutations } from '@/store/modules/dreams/names';

import ChangeField from './ChangeField.vue';
import DreamTreeRelations from './DreamTreeRelations.vue';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'DreamItem',

    components: { ChangeField, DreamTreeRelations },

    data() {
        return {
            selectList: [
                'Пользовательская',
                'Благотворительная',
            ],
            typeDream: '',
            imageChange: null,
            dialog: false,
            isShowRelations: false,
            isHideLanding: false,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            dream: (state: ComposedState) => state.dreams.dreamItem,
            categoryList: (state: ComposedState) => state.dreams.categoryList,
        }),
    },

    async mounted() {
        await this.getDreamItem(this.$route.params.id);

        this.isShowRelations = true;
        
        this.isHideLanding = this.dream.hide_landing ?? false;
        
        if (this.dream.type_dream) {
            this.typeDream = this.dream.type_dream;
        }
    },

    methods: {
        ...mapActions({
            changeDreamItem: dreamsActions.changeDreamItem,
            getDreamItem: dreamsActions.getDreamItem,
        }),

        ...mapMutations({
            updateDreamChangeItem: dreamsMutations.UPDATE_DREAM_CHANGE_ITEM,            
        }),

        async sendPicture() {
            if (!this.imageChange) {
                return;
            }
            this.updateDreamChangeItem({ title: 'picture', value: this.imageChange });
            await this.changeDreamItem();
        },

        async changeDreamType() {
            this.updateDreamChangeItem({ title: 'type_dream', value: this.typeDream });
            await this.changeDreamItem();
        },

        async changeHideLanding() {
            this.isHideLanding = !this.isHideLanding;
            this.updateDreamChangeItem({ title: 'hide_landing', value: this.isHideLanding });
            await this.changeDreamItem();
        },
    },
});
