
import Vue from 'vue';

import { mapState, mapActions, mapMutations } from 'vuex';

import { dreamsActions, dreamsMutations } from '@/store/modules/dreams/names';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'ChangeField',

    props: {

        // Пропс с ключём из объекта который мы хотим изменить
        changeField: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        // Определяет инпут как селект
        isSelect: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Если объект, то определяет ключ, на основе которого будет отрисовка
         * итемов селекта (не объект лучше не передавать)
         */
        selectText: {
            type: String,
            required: false,
        },

        // Если объект, то определяет ключ, значение которого передаст в v-model 
        selectValue: {
            type: String,
            required: false,
        },

        // Массив объектов на основе которого отрисовка
        selectList: {
            type: Array,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },

        // Тип поля с текстом
        type: {
            type: String,
        },
    },

    data() {
        return {
            editNameDialog: false,
            btnState: true,
            CategoryValue: [],
            setName: '',

            process: {
                state: false,
                preloader: true,
                icon: false,
            },
        };
    },

    computed: {
        ...mapState<ComposedState>({
            dreamItem: (state: ComposedState) => state.dreams.dreamItem,
        }),
        nameModel: {
            get(): string {
                return this.name;
            },
            set(val: string) {
                this.setName = val;
            },
        },
    },

    methods: {
        ...mapActions({
            changeDreamItem: dreamsActions.changeDreamItem,
            getDreamItem: dreamsActions.getDreamItem,
        }),
        ...mapMutations({
            updateDreamChangeItem: dreamsMutations.UPDATE_DREAM_CHANGE_ITEM,
        }),

        async submit() {
            this.process.state = true;
            this.process.preloader = true;
            this.process.icon = false;

            /** 
             * Первый параметр - ключ который хотим изменить в объекте для изменения мечты.
             * Второй параметр - его значение.
             */
            this.updateDreamChangeItem({
                title: this.changeField,
                value: this.setName,
            });

            // Отправляет запрос на бэк с изменением мечты
            await this.changeDreamItem();
            await this.getDreamItem(this.$store.state.dreams.dreamItem.id);
            this.process.preloader = false;
            this.process.icon = true;
            setTimeout(() => {
                this.editNameDialog = false;
                this.process.state = false;
            }, 1000);
        },
    },
});
